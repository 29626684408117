import './tableHead.css'
import { useEffect, useState } from 'react';

const TableHead = (props) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const cells = props.cells;
  const page = props.page;
  const activeColumn = props.activeColumn;

  console.log(cells)


  return(
    <>
      <div className={page === 'users' ? 'table-head-filter-wrapper table-head-filter-wrapper-users' : 'table-head-filter-wrapper'}>
        <div className={isMobile ? ' table-head table-head-mobile' : "table-head"}>
          <ul className="table-head-list">
            <li className={isMobile ? ' table-head-item table-head-item-mobile' : "table-head-item"}>
              <p>
                {cells[0]}
              </p>
            </li>
            <li className={isMobile ? activeColumn === 0 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
              <p>
                {cells[1]}
              </p>
            </li>
            <li className={isMobile ? activeColumn === 1 ? ' table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
              <p>
                {cells[2]}
              </p>
            </li>
            <li className={isMobile ? activeColumn === 2 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
              <p>
                {cells[3]}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default TableHead;
