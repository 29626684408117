import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Polyline} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { icon } from "leaflet";

const MapPopup = ({show, onClose, mainRoute, route}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const cleanWaypoints = (routes) => {
    const uniqueWaypoints = routes.filter((point, index) => {
      return (
        index ===
        routes.findIndex((p) => p[0] === point[0] && p[1] === point[1])
      );
    });
    return uniqueWaypoints.filter(point => {
      return point.length === 2 && !isNaN(point[0]) && !isNaN(point[1]);
    });
  };

  const processRouteObject = (routeObj) => {
    const waypoints = [];
    for (const key in routeObj) {
      if (routeObj.hasOwnProperty(key)) {
        const value = routeObj[key];
        if (typeof value === "string" && value.includes(",")) {
          const [lat, lng] = value.split(",").map((coordinates) => parseFloat(coordinates.trim()));
          waypoints.push([lat, lng]);
        }
      }
    }
    return cleanWaypoints(waypoints);
  };

  let routes = [];

  if (Array.isArray(route)) {
    routes = route.map((routeObj) => ({
      waypoints: processRouteObject(routeObj),
    }));
  } else if (typeof route === "object" && route !== null) {
    routes = [{ waypoints: processRouteObject(route) }];
  }

  const colors = ["red", "purple", "green", "blue", "orange"];
  const mainRouteWaypoints = processRouteObject(mainRoute);

  const startEndIcon = new L.icon({
    iconUrl: require('../../Images/start-end-icon.png'),
    iconSize: [15, 20],
    iconAnchor: [1, 20],
  });

  console.log(mainRouteWaypoints);


  if(!show) return null;

  return(
    <>
      <section className={isMobile ? 'overlay-darker modal-container' : 'overlay-shift overlay-darker modal-container'}>
        <div className="modal">
          <button className={isMobile ? 'modal-button mobile-modal-button' : "modal-button"} onClick={onClose}></button>
          <MapContainer center={mainRouteWaypoints[0]} zoom={7}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {mainRouteWaypoints.length > 0 && (
              <>
                <Polyline key="mainRoute" positions={mainRouteWaypoints} color="grey" />
                <Marker position={mainRouteWaypoints[0]} icon={startEndIcon} />
                <Marker position={mainRouteWaypoints[mainRouteWaypoints.length - 1]} icon={startEndIcon} />
              </>
            )}
            {routes.flatMap((route, index) => {
              const waypoints = route.waypoints;
              if(waypoints.length > 0) {
                return [
                  <Marker key={`${index}-start`} position={waypoints[0]}></Marker>,
                  <Marker key={`${index}-end`} position={waypoints[waypoints.length - 1]}></Marker>
                ];
              }
              return[];
            })}
            <Polyline key={mainRoute} positions={mainRouteWaypoints} color="grey"/>
            {routes.map((route, index) => (
              <Polyline key={index} positions={route.waypoints} color={colors[index % colors.length]} />
            ))}
          </MapContainer>
        </div>
      </section>
    </>
  )
}

let DefaultIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  iconSize: [10, 15],
    iconAnchor: [5, 15],
    popupAnchor: [1, -12],
});
L.Marker.prototype.options.icon = DefaultIcon;

export default MapPopup;
