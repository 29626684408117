import { useEffect, useCallback, useRef } from 'react';
import './modalDelete.css';
 
const ModalDelete = (props) => {
  const { modalRef, message, actionText, action, onClose } = props;
  // console.log(onClose)
  // const modalRef = useRef(null);
  // const isModalDeleteOpened=props.isModalDeleteOpened;

  // const handleClickOutside = useCallback((event) => {
  //   if(modalRef.current && !modalRef.current.contains(event.target)) {
  //     onClose();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isModalDeleteOpened) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   }
  //   else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }
  // }, [isModalDeleteOpened, handleClickOutside]);
  return(
    <>
      <div className="modal-window modal-window_delete">
        <div className="modal-window__wrapper modal-window_delete__wrapper" ref={modalRef}>
          <p>{message}</p>
          <section className='modal-window__buttons-block modal-window_delete__buttons-block'>
            <button className='modal-window__button_delete button_reverse_deletion' onClick={() => onClose()}>Отменить</button>
            <button className='modal-window__button_delete button_deletion' onClick={() => action() }>{actionText}</button>
          </section>
        </div>
      </div>
    </>
  )
}
export default ModalDelete;