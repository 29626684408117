import { useState } from 'react';
import '../../App.css';
import './taskTracker.css';
import NavigationMenu from '../../Components/NavigationMenu';
import TrackerHeader from './MainPage/Header.jsx';
import TasksCard from './MainPage/AllTasksCard.jsx';
import PeriodSwitcher from './MainPage/PeriodSwitcher.jsx';
import ModalDelete from '../Service/ModalDelete.jsx';

const Tracker = ({accessToken}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const tasksStatus = ['Выполнено', 'В процессе', 'Запланировано'];


  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleChangeDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

 return(
  <>
    <div className='body-wrapper tracker-body-wrapper'>
      <div className="page-wrapper page-flex-wrapper">
        <NavigationMenu currentPage='tracker' accessToken={accessToken}/>
        <main className='tracker-main__wrapper'>
          <TrackerHeader
            startDate={startDate}
            endDate={endDate}
          />
          <PeriodSwitcher
            accessToken={accessToken}
            onChangeDates={handleChangeDate}
          />
          <div className="tracker-main-tasks-cards__container">

          {tasksStatus.map((item, index) => (
            <TasksCard
              key={index}
              typeOfTasks={item}
              onOpen={handleOpenModal}
              startDate={startDate}
              endDate={startDate}
              accessToken={accessToken}
            />
          ))}
          </div>
        </main>
        {
          isModalOpen &&
          <ModalDelete
            message='Удалить направление?'
            action='Удалить'
            onClose={handleCloseModal}
          />
        }
      </div>
      </div>
  </>
 )
}
export default Tracker;
