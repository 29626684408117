import './service.css';
import { useEffect, useCallback, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../Images/Menu/logo.svg';
import DirectionBlock from './DirectionBlock';
import DirectionForm from './DirectionForm';
import NavigationMenu from '../../Components/NavigationMenu';
import apiConfigTest from "../../apiConfig.json";
import ModalDelete from './ModalDelete';


const ServicePage = ({accessToken}) => {

  // const navigate = useNavigate();
  const [isDirectionFormOpen, setIsDirectionFormOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [directions, setDirections] = useState([{}]);
  const colors = ['#9B69BA', '#708EC9', '#E5A162', '#F0D164'];

  // const handleLogout = () => {
  //   localStorage.removeItem('accessToken');
  //   navigate('/');
  //   window.location.reload();
  // }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleButtonClick = () => {
    setIsDirectionFormOpen(!isDirectionFormOpen);
    setIsFormVisible(false);
  };

  const handleTransitionEnd = () => {
    if (isDirectionFormOpen) {
      setIsFormVisible(true);
    }
  };

  const getDirections = useCallback(() => {
    fetch(`${apiConfigTest.BASE_URL}${apiConfigTest.ENDPOINTS.GET_DIRECTIONS}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
   .then(response =>  {
     return response.json();
   })
   .then(data => {
    const dirInfo = [];
     data.forEach(dir => {
      const directionId = dir.id;
      const directionName = dir.name;
      const areas = [];
      dir.places.forEach(area => {
        areas.push(area.name);
      })
      dirInfo.push({directionId, directionName, areas});
    });

    setDirections(dirInfo);
   })
   .catch(error => {
     console.error('Error fetching data: ', error);
   });
 },[accessToken]);

 useEffect(() => {
   getDirections();
 }, []);

 const handleFormSubmit = () => {
   getDirections();
 };

  return(
    <>
    <div className='body-wrapper'>
      <div className="page-wrapper page-flex-wrapper">
        <NavigationMenu currentPage='service' accessToken={accessToken}/>
        <main className='main-index__wrapper service-main-index'>
          <section className='service-container'>
            <section className='directions-container'>
              <h1>Направления</h1>
              <div className={`directions-wrapper ${isDirectionFormOpen ? 'collapsed' : ''}`}>

              {directions.map((direction, index) => (
                    <DirectionBlock
                        key={index}
                        id={direction.directionId}
                        direction={direction.directionName}
                        areas={direction.areas}
                        color={colors[index % colors.length]}
                        onDeleteClick={handleOpenModal}
                    />
                ))}

              </div>
            </section>
          </section>
          <button className={`button button-add-direction ${isDirectionFormOpen ? 'open' : ''}`} onClick={handleButtonClick} onTransitionEnd={handleTransitionEnd}>+</button>

          {isFormVisible && (
            <>
              <button className='button-close-form' onClick={handleButtonClick}></button>
              <DirectionForm onFormSubmit={handleFormSubmit} accessToken={accessToken} onClose={handleButtonClick}/>
            </>
          )
          }
          {
            isModalOpen &&
            <ModalDelete
            message='Удалить направление?'
            action='Удалить'
            onClose={handleCloseModal}/>
          }
        </main>
      </div>
      </div>
    </>
  )
}

export default ServicePage;
