import { useState, useEffect, useRef } from "react";
import './filter.css'
import '../UserEquipAddition/additionPage.css';

const Filters = ({onFilters}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [expanded, setExpanded] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [dateParameterClicked, setDateParameterClicked] = useState(false);
  const [technicTypeClicked, setTechnicTypeClicked] = useState(false);
  const [selectedTechnicType, setSelectedTechnicType] = useState('');
  const categoryColors = {
    'ФИО': '#6B66BB',
    'Логин': '#6B66BB',
    'VIN': '#F0D164',
    'Модель': '#F0D164',
    'Гаражный номер': '#F0D164',
    'Тип техники': '#F0D164',
    'Дата начала': '#E5A162'
  }
  const categoriesWrapperRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoriesWrapperRef.current && !categoriesWrapperRef.current.contains(event.target)  && event.target !== buttonRef.current) {
        setCategoriesOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleCategoryMenu = () => {
    setCategoriesOpen(!categoriesOpen);
    setExpanded(false);
  }

  const openInput = (category) => {
    // setExpanded(true);
    setCategory(category);
    setCategoryOpen(true);
    setCategoriesOpen(false);
    console.log('category open')
    // document.getElementById('filter-parameters').focus();
    if (category === "Дата начала" || category === "Дата конца") {
      setDateParameterClicked(true);
      setTechnicTypeClicked(false);
    } else if (category === 'Тип техники') {
      setTechnicTypeClicked(true);
      setDateParameterClicked(false);
    } else {
      setDateParameterClicked(false);
      setTechnicTypeClicked(false);
    }
  }

  const handleInputChange = (event) => {
    setFilterValue(event.target.value);
  }

  const handleAddFilter = (category, technicType) => {
    if(filterValue.trim() !== "") {
        const newFilter = { category: category, value: filterValue}
        setFilterList([...filterList, newFilter]);
        setFilterValue('');
    }
    if(category === 'Тип техники') {
      const newFilter = { category: category, value: technicType}
      setFilterList([...filterList, newFilter]);
      setSelectedTechnicType('');
    }
  }

  const handleRemoveFilter = (index) => {
    const updatedFilterList = filterList.filter((_, i) => i !== index);
    setFilterList(updatedFilterList);


    if (updatedFilterList.length === 0) {
      onFilters({});
      setExpanded(false);
      setCategoriesOpen(false);
    } else {
      const updatedFilters = updatedFilterList.reduce((acc, curr) => {
        let translatedValue = curr.value;
        if (curr.category === 'Дата начала' || curr.category === 'Дата конца') {
          const dateParts = curr.value.split('.');
          if (dateParts.length === 3) {
            translatedValue = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          }
        }
        const translatedCategory = categoryTranslation[curr.category] || curr.category;
        if (!acc[translatedCategory]) {
          acc[translatedCategory] = [];
        }
        acc[translatedCategory].push(translatedValue);
        return acc;
      }, {});
      const filtersMap = new Map(Object.entries(updatedFilters).map(([key, value]) => [key, value]));
      let requestBody = {};
      if (filtersMap.size > 0) {
        requestBody = {
          filters: Object.fromEntries(filtersMap)
        };
      }
      onFilters(requestBody);
    }
  }

  const categoryTranslation = {
    'Дата начала' : 'start',
    'Логин' : 'name',
    'ФИО' : 'fullname',
    'VIN' : 'vin',
    'Модель' : 'model',
    'Тип техники' : 'type',
    'Гаражный номер' : 'warrantynumber'
  }

  const getImageForEquipmentType = (equipmentType) => {
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const filters = filterList.reduce((acc, curr) => {
    let translatedValue = curr.value;
    const translatedCategory = categoryTranslation[curr.category] || curr.category;
    if(translatedCategory === 'warrantynumber') {
      translatedValue = parseInt(curr.value, 10);
    }
    if(!acc[translatedCategory]) {
      acc[translatedCategory] = [];
    }
    acc[translatedCategory].push(translatedValue);
    return acc;
  }, {});

  const sendFiltersToMain = () => {
    const filtersMap = new Map(Object.entries(filters).map(([key, value]) => [key, value]));
    let requestBody = {};
    if (filtersMap.size > 0) {
        requestBody = {
            filters : Object.fromEntries(filtersMap)
        };
    }
    if (selectedTechnicType) {
      requestBody = {
        ...requestBody,
        technictype: selectedTechnicType
      };
    }
    onFilters(requestBody);
    setCategoriesOpen(false);
    setExpanded(false);
    setCategoryOpen(false)
    }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddFilter(category);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      sendFiltersToMain();
      setCategoryOpen(false);
    }
  };

  const handleTechnicTypeClick = (technicType) => {
    setSelectedTechnicType(technicType);
    handleAddFilter('Тип техники', technicType);
  }

  console.log(filters);


  return(
    <>
          <section className={ isMobile ? 'filter filter-mobile' : 'filter'}>
            <div className={isMobile ? 'filter-wrapper-mobile' : 'filter-wrapper'}>
              <button ref={buttonRef} className='button-filter' onClick={handleCategoryMenu}></button>
              {
                categoryOpen && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{ backgroundColor: categoryColors[category] }}>
                        <button className="filter-category-wrapper__close-button" type="button" onClick={() => setCategoryOpen(false)}></button>
                        <input
                          type="text"
                          className="filter-category-wrapper__input"
                          placeholder={`Введите ${category}`}
                          value={filterValue}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                        />
                        <button
                          className="filter-category-wrapper__button"
                          onClickCapture={() => handleAddFilter(category)}
                          onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              {
                categoryOpen && dateParameterClicked && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{ backgroundColor: categoryColors[category] }}>
                        <button className="filter-category-wrapper__close-button" type="button" onClick={() => setCategoryOpen(false)}></button>
                        <input
                          type="date"
                          className="filter-category-wrapper__input"
                          placeholder={`Введите ${category}`}
                          value={filterValue}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                        />
                        <button
                          className="filter-category-wrapper__button"
                          onClickCapture={() => handleAddFilter(category)}
                          onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              {
                categoryOpen && technicTypeClicked && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{ backgroundColor: categoryColors[category]}}>
                        <button className="filter-category-wrapper__close-button" type="button" onClick={() => setCategoryOpen(false)}></button>
                        <div className="filter-category-wrapper__type-list">
                          <button type='button' className="button-apply-equipment technic-type-white-excavator" onClickCapture={() => handleTechnicTypeClick('EXCAVATOR')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-bulldozer" onClickCapture={() => handleTechnicTypeClick('BULLDOZER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-crane" onClickCapture={() => handleTechnicTypeClick('CRANE')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-grader" onClickCapture={() => handleTechnicTypeClick('GRADER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-rink" onClickCapture={() => handleTechnicTypeClick('RINK')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-truck" onClickCapture={() => handleTechnicTypeClick('TRUCK')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-auto" onClickCapture={() => handleTechnicTypeClick('AUTO')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-pooker" onClickCapture={() => handleTechnicTypeClick('POOKER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-refueler" onClickCapture={() => handleTechnicTypeClick('REFUELER')}></button>
                        </div>
                        <button
                          className="filter-category-wrapper__button"
                          onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              <div className="filter__applied-filter-wrapper">
                {
                  filterList.map((filter, index) => {
                    if(filter.category === 'Дата начала' || filter.category === 'Дата конца') {
                      const dateParts = filter.value.split('-');
                      const filterValue = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
                      return (
                        <li key={index} className="filter-item" style={{backgroundColor: categoryColors[filter.category]}}>
                          {filterValue}
                          <button onClick={() => handleRemoveFilter(index)}>x</button>
                        </li>
                      )
                    } else if (filter.category === 'Тип техники') {
                      const getImageForEquipmentType = (equipmentType) => {
                        switch (equipmentType) {
                          case 'EXCAVATOR':
                            return require('../../Images/Equipment/excavator_32.png');
                          case 'BULLDOZER':
                            return require('../../Images/Equipment/bulldozer_32.png');
                          case 'CRANE':
                            return require('../../Images/Equipment/crane_32.png');
                          case 'GRADER':
                            return require('../../Images/Equipment/grader_32.png');
                          case 'RINC':
                            return require('../../Images/Equipment/rinc_32.png');
                          case 'RINK':
                            return require('../../Images/Equipment/rink_32.png');
                          case 'TRUCK':
                            return require('../../Images/Equipment/truck_32.png');
                          case 'POOKER':
                            return require('../../Images/Equipment/camera_10.png');
                          case 'AUTO':
                            return require('../../Images/Equipment/auto_32.png');
                          case 'REFUELER':
                            return require('../../Images/Equipment/refueler_512.png');
                          default:
                            return require('../../Images/Equipment/excavator_32.png');
                        }
                      };
                      return(

                          <li key={index} className="filter-item filter-item-technic-type" style={{backgroundColor: categoryColors[filter.category]}}>
                            <img src={getImageForEquipmentType(filter.value)} width={25} height={20} alt="тип техники" className="technic-type-chosen"/>
                            <button onClick={() => handleRemoveFilter(index)}>x</button>
                          </li>
                      )
                    }
                    const filterValue = filter.value;
                    return(
                      <li key={index} className="filter-item" style={{backgroundColor: categoryColors[filter.category]}}>
                        {filterValue}
                        <button onClick={() => handleRemoveFilter(index)}>x</button>
                      </li>
                    )
                  })
                }
              </div>
              {
              categoriesOpen &&
              <div ref={categoriesWrapperRef} className={isMobile ? 'categories-wrapper categories-wrapper-mobile' : 'categories-wrapper'}>
              <ul className="categories-list">
                <li className="categories-items">
                  <button onClick={() => openInput('Дата начала')}>Дата начала</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Логин')}>Логин</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('ФИО')}>ФИО</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('VIN')}>VIN</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Модель')}>Модель</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Тип техники') && handleCategoryMenu}>Тип техники</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Гаражный номер')}>Гаражный номер</button>
                </li>
              </ul>
            </div>
            }
            </div>
      </section>
    </>
  )
}
export default Filters
;


