import { useState, useCallback, useEffect, useRef } from 'react';
import ModalNote from '../../Components/ModalNote.jsx'
import apiConfig from "../../apiConfig.json";

const UserForm = ({accessToken, onFormSubmit}) => {
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  // const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);

  const [directions, setDirections] = useState([]);
  const [chosenDirPlace, setChosenDirPlace] = useState({name:null, id:null});
  const [chosenIdType, setChosenIdType] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [isDirListOpen, setIsDirListOpen] = useState(false);
  const [isPlacesListOpen, setIsPlacesListOpen] = useState(false);
  const [placesListSates, setPlacesListSates] = useState({});
  const [isFullnameBad, setIsFullNameBad] = useState(false);
  const [isPasswordBad, setIsPasswordBad] = useState(false);
  const [fullnameError, setFullnameError] = useState('Заполните это поле.');
  const [passwordError, setPasswordError] = useState('Заполните это поле.');

  const [rolesListError, setRolesListError] = useState({text: 'Выберите роль.', state: false});
  const [equipListError, setEquipListError] = useState({text: 'Выберите хотя бы один тип оборудования.', state: false});
  const [dirplacesError, setDirplacesError] = useState({text: 'Выберите хотя бы одно направление или участок.', state: false});
  const [submitError, setSubmitError] = useState({text: 'Заполните все поля, чтобы продолжить.', state: false});

  // const [isPasswordCopied, setIsPasswordCopied] = useState(false);
  // const [isUsernameCopied, setIsUsernameCopied] = useState(false);
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);
  // const [errorMessage, setErrorMessage] = useState({isOpened: false, message: 'Что-то пошло не так...'});
  // const [isTextCopied, setIsTextCopied] = useState(false);
  // const errorMessageRef = useRef(null);
  const dirPlacesInputRef = useRef(null);
  const placesListRefs = useRef({});
  // const copyTextUsernameRef = useRef(null);
  // const copyTextPasswordRef = useRef(null);
  // const isFieldEmptyRef = useRef(null);

  const ROLES = {
    hr: 'HR',
    mechanic: 'MECHA',
    user: 'USER',
    analytic: 'ANAL'
  }

  const EQUIPS = [
    'EXCAVATOR',
    'BULLDOZER',
    'CRANE',
    'GRADER',
    'RINK',
    'TRUCK',
    'REFUELER',
    'AUTO',
    'POOKER'
  ]

  // useEffect(() => {
  //   if(isTextCopied) {
  //     const timer = setTimeout(() => {
  //       setIsTextCopied(false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isTextCopied]);

  const getDirections = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
   .then(response =>  {
     return response.json();
   })
   .then(data => {
    // console.log(data);

    const dirs = [];

    data.forEach((dir) => {
      const places = [];
      const name = dir.name;
      const direction_id = dir.id;

      if(dir.place.length > 0) {
        dir.place.forEach((place) => {
          const name = place.name;
          const place_id = place.id;
          places.push({name, place_id});
        });
      } else {
      }

      dirs.push({name, direction_id, places});
    });
    setDirections(dirs);
   })
   .catch(error => {
     console.error('Error fetching data: ', error);
   });
 },[accessToken]);

 useEffect(() => {
   getDirections();
 }, [accessToken]);

 const handleCloseNoteModal = () => {
  setIsModalNoteOpened(false);
}

 const toggleDirList = () => {
  console.log(placesListSates)
  setIsDirListOpen(!isDirListOpen);
  };

 const togglePlacesList = (index) => {
  // console.log(placesListSates)
  // console.log(isPlacesListOpen)
   setIsPlacesListOpen(!isPlacesListOpen)
   try {
    //  console.log(isPlacesListOpen);
     if(!isPlacesListOpen) {
       setPlacesListSates(prevStates => ({
         ...prevStates,
         [index]: true
        }));
        // console.log('это... сработало?', placesListSates, index)
        console.log('это... сработало?', isPlacesListOpen)
      } else{
        setPlacesListSates(prevStates => ({
          ...prevStates,
          [index]: false
        }));
      }
      // console.log(isPlacesListOpen,placesListSates[index], placesListSates)
  } catch (error){
    console.log("Oops! Failed to open places list: ", error)
  }
  };

  const handleFullNameChange = (event) => {
    const re = /\d/g;
    setFullName(event.target.value);
    if (re.test(String(event.target.value))) {
      setFullnameError('В ФИО не может быть цифр.')
    }
    else if (!event.target.value) {
      setFullnameError('ФИО не может быть пустым.');
    } else {
      setFullnameError('')
    }
  };

  const handlePasswordChange = (event) => {
    const re = /\s/g;
    setPassword(event.target.value);
    if(re.test(String(event.target.value))) {
      setPasswordError('В пароле не может быть пробелов.');
    }
    else if (!event.target.value) {
      setPasswordError('Пароль не может быть пустым.');
    } else {
      setPasswordError('');
    }
  };


  const handleRoleChange = (event) => {
  let newRole = []
   newRole.push(event.target.value);
  // setRole(prev => prev.length = 0);
  if(role.length === 0) {
    setEquipListError({text: 'Выберите хотя бы один тип оборудования.', state: true});
    console.log(equipListError.state);
  } else {
    setEquipListError(prev => ({...prev, state: false}));
  }
    setRole(newRole);
  };

  // const handleSwitchRole = (role) => {
  //   // console.log(role)
  //   if(!rolesList.includes(role)) {
  //     setRolesListError({state: false});
  //     setRolesList([...rolesList, role]);
  //   }
  //   else {
  //     const updatedRolesList = rolesList.filter((_,i) => i !== rolesList.indexOf(role));
  //     if(updatedRolesList.length === 0) {
  //       setRolesListError({text: "Выберите хотя бы одну роль.", state: true});
  //     }
  //     setRolesList(updatedRolesList);
  //   }
  // }

  // const handleInputEquipmentChange = (e) => {
  //   // setEquipmentType(type);
  //   // setIsEquipTypeSelected(e.target.value);
  //   handleSwitchEquipment(e.target.value);
  // }

  // const handleSwitchEquipment = (type) => {
  //   if(!equipmentList.includes(type)) {
  //     setEquipmentList([...equipmentList, type]);
  //     setIsEquipTypeSelected('');
  //     // setEquipmentType('');
  //   }
  //   else {
  //     const updatedEquipmentList = equipmentList.filter((_,i) => i !== equipmentList.indexOf(type));
  //     setEquipmentList(updatedEquipmentList);
  //   }
  // };
  // useEffect(() => {
  //     if(equipmentList.length === 0) {
  //       setEquipListError({text: 'Выберите хотя бы один тип оборудования.', state: true});
  //       console.log(equipListError.state);
  //     } else {
  //       setEquipListError({state: false});
  //     }

  // }, [equipmentList, equipListError.state])

  const handleSwitchEquipment = (type) => {
    setEquipmentList(prevList => {
      if(!prevList.includes(type)) {
      setEquipListError({state: false});
        return [...prevList, type];
      } else {
        const newEquipmentList = prevList.filter(item => item !== type );
        if(newEquipmentList.length === 0) {
          setEquipListError({text: 'Выберите хотя бы один тип оборудования.', state: true});
          // console.log(equipListError.state);
        }
        return newEquipmentList;
      }
    });
  };

  const handleSelectDirPlace = (index, name, id, type) => {
    setSelectedItem(index);
    setChosenDirPlace({name, id});
    setChosenIdType(type);
    setIsDirListOpen(false);
    setIsPlacesListOpen(prevState => ({
      ...prevState,
      [index]: false
    }));
  };

  const handleBlur = (event) => {
    switch (event.target.name) {
      case 'fullName':
        setIsFullNameBad(true);
        break;
      case 'password':
        setIsPasswordBad(true);
        break;
      default:
        setIsFullNameBad(false);
        setIsPasswordBad(false);

    }
  }

  // const handleCopyText = async (ref, setCopied) => {
  //   const copiedText = ref.current.value;
  //   try {
  //     await navigator.clipboard.writeText(copiedText);
  //     setCopied(true);
  //     setTimeout(() => setCopied(false), 2000);
  //   } catch (error) {
  //     // console.log("failed triyng to copy text: ", error);
  //   }
  // };

  const makeUsername = (name) => {
    const nameParts = name.split(' ');
    const lastName = nameParts[0];
    const firstNameInitial = nameParts[1].charAt(0);
    const middleNameInitial = nameParts[2].charAt(0);
    return `${lastName}${firstNameInitial}${middleNameInitial}`;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!chosenDirPlace.id) {
      setDirplacesError({text: 'Выберите хотя бы одно направление или участок.', state: true});
    } else {
      setDirplacesError(prev => ({...prev, state: false}));
    }

    if (!fullName.length || !password.length || !role.length || !equipmentList.length || !chosenDirPlace.id ) {
      setSubmitError({text: 'Заполните все поля, чтобы продолжить.', state: true});
      return;
    }
    setSubmitError({state: false});
    const username = makeUsername(fullName)
    setUsername(username);
    let id = chosenDirPlace.id;
    let formData = {};

    if (chosenIdType === 'directionId' ) {
      formData = {
        username: username,
        password: password,
        fullName: fullName,
        specialisations: equipmentList,
        // roles: role,
        direction_id: id
      };
    } else if (chosenIdType === 'placeId') {
      formData = {
        username: username,
        password: password,
        fullName: fullName,
        specialisations: equipmentList,
        roles: role,
        place_id : id
      };
    }

    id=0;

    setSelectedItem('');
    setChosenIdType('');
    setChosenDirPlace({name:null, id:null});

    // console.log(formData)
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_USER}`, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      setDirplacesError(prev => ({...prev, state: false}));
      if(response.ok) {
        onFormSubmit();
        setIsModalNoteOpened(true);
        // setIsFormSubmitted(true);
        console.log('new user post worked!');
      }
    })
    .catch(error => {
      console.log(error);
    })
  };

  return (
      <>
    <section className="addition-form-wrapper">
    {/* {isFormSubmitted === false ? ( */}
      <>
        {/* {errorMessage.isOpened &&
          <div className={`additional__form-message__wrapper ${errorMessage.isOpened ? 'showed' : ''}`}>
            <p className='additional__form__error-message-note' ref={errorMessageRef}>{errorMessage.message}</p>
          </div>
        } */}
         <form className='addition-form' onSubmit={handleSubmit} method='post'>
          <div className="addition-form__input-wrapper">
            <label htmlFor='full name'>ФИО</label>
            <input
              id='full name'
              name='fullName'
              className='addition-form-input'
              placeholder='Введите ФИО'
              value={fullName}
              // ref={isFieldEmptyRef}
              onChange={(e) => handleFullNameChange(e)}
              onBlur={(e) => handleBlur(e)}
              style={(isFullnameBad && fullnameError) ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
            />
            {(isFullnameBad && fullnameError) &&
              <p className="validation-error-message">{fullnameError}</p>
            }
          </div>

          <div className="addition-form__input-wrapper">

          <label htmlFor='password'>Пароль</label>
          <input
            id='password'
            name='password'
            type='text'
            className='addition-form-input'
            placeholder='Введите пароль'
            value={password}
            // ref={isFieldEmptyRef}
            onBlur={(e) => handleBlur(e)}
            onChange={(e) => handlePasswordChange(e)}
            style={(isPasswordBad && passwordError) ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
            />
          {(isPasswordBad && passwordError) &&
            <p className="validation-error-message">{passwordError}</p>
          }
          </div>

          <div className="addition-form__input-wrapper">
            <label htmlFor='role'>Должность</label>
            <div className="addition-button-type-list">
              <button id='role' type='button' className={`addition-button button-add-member-employee-with-workers ${role.includes(ROLES.hr) ? 'selected' : ''}`}
                // onClick={(e) => handleSwitchRole(e.target.value)}
                onClick={(e) => handleRoleChange(e)}
                value={ROLES.hr}
                >
                Работник с персоналом
              </button>
              <button id='role' type='button' className={`addition-button button-add-member-mechanic ${role.includes(ROLES.mechanic) ? 'selected' : ''}`}
                // onClick={(e) => handleSwitchRole(e.target.value)}
                onClick={(e) => handleRoleChange(e)}
                value={ROLES.mechanic}
                >
                Механик
              </button>
              <button id='role' type='button' className={`addition-button button-add-member-worker ${role.includes(ROLES.user) ? 'selected' : ''}`}
                // onClick={(e) => {handleSwitchRole(e.target.value)}}
                onClick={(e) => handleRoleChange(e)}
                value={ROLES.user}
              >
                Рабочий
              </button>
              <button id='role' type='button' className={`addition-button button-add-member-analytic ${role.includes(ROLES.analytic) ? 'selected' : ''}`}
                // onClick={(e) => handleSwitchRole(e.target.value)}
                onClick={(e) => handleRoleChange(e)}
                value={ROLES.analytic}
                >
                Аналитик
              </button>
            </div>
            {(rolesListError.state) &&
              <p className="validation-error-message">{rolesListError.text}</p>
            }
          </div>

          <div className="addition-form__input-wrapper">
            <div className='dirplaces-list-container'>
              <label>Направления и участки</label>
              <div className="addition-form-input dirplaces-input analytics-item">
                {/* <p>Направления и участки</p> */}
                { chosenDirPlace.name ? (
              <>
                <p>{chosenIdType === 'directionId' ? 'Выбранное направление' : 'Выбранный участок'}: </p>
                <p className="chosen-dirplace-text">{chosenDirPlace.name}</p>
              </> )
              : dirplacesError.state ? (
                <p className="validation-error-message">{dirplacesError.text}</p>
              )
              : (
                <p>Выберите направление либо участок</p>
              )
            }
                <button type='button' className={'button' && isDirListOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleDirList}>
                </button>
              </div>

              <div className={`dirplaces ${isDirListOpen ? 'open' : ''}`}>

              {isDirListOpen && (
              <div className="dirplaces-list-wrapper" ref={dirPlacesInputRef}>
              {/* <p className="dirplaces-list-note">выберите направление либо участок</p> */}

              {/* <p className="dirplaces-list-item-heading">Доступные направления</p> */}
              <ul className='dirplaces-list'>

                {directions.map((dir, index) => (
                <div className={`dirplaces-list__wrapper `}
                // style = {{zIndex: `${(!isPlacesListOpen && placesListSates[index] === index) ? 20 : 1}`}}
                >
                {/* <p className='dirplaces-list-item-heading dirplaces-list-item-heading-direction'>Направление #{index+1}</p> */}
                  <li
                    key={index}
                    className={`dirplaces-list-item ${(selectedItem === index && chosenIdType !== 'placeId') ? 'selected-item' : ''} ${(isPlacesListOpen && placesListSates[index]) ? 'opened-list' : ''}`}
                    onClick={() => handleSelectDirPlace(index, dir.name, dir.direction_id, 'directionId')}
                    >
                    <span>
                      {dir.name}
                    </span>
                  </li>
                    <button ref ={(list) => (placesListRefs.current[index] = list)} type='button' className={`places-list__button ${isPlacesListOpen ? 'button-close-places' : 'button-open-places'} ${(isPlacesListOpen && placesListSates[index]) ? 'opened-list' : ''}`} onClick={() => togglePlacesList(index)} ></button>
                  {/* <p className="dirplaces-list-item-heading dirplaces-list-item-heading-places">Доступные участки</p> */}

                  <div ref ={(list) => (placesListRefs.current[index] = list)} className={`places ${(isPlacesListOpen && placesListSates[index]) ? 'open' : ''}`}>

                  {(isPlacesListOpen && placesListSates[index]) && (
                    <div className="places-list-wrapper">
                      {/* <p>{dir.name}</p> */}
                    <ul className="dirplaces-list dirplaces-list-places">
                    {dir.places.length > 0 ? (
                      <>
                      {dir.places.map((place, index) => (

                        <li key={index}
                        className={`places-list-item ${(selectedItem === index && chosenIdType !== 'directionId') ? 'selected-item' : ''}`}
                        onClick={() => handleSelectDirPlace(index, place.name, place.place_id, 'placeId')}
                        >
                          <span>
                          {place.name}
                          </span>
                        </li>
                      ))}
                    </>
                      ) : (
                        <p className='dirplaces-empty-list-note'>У этого направления нет участков.</p>
                      )
                    }
                  </ul>
                    </div>
                   )}
                  </div>
                </div>
                ))}
              </ul>
            </div>
            )
          }
          </div>

          </div>
          </div>

          <div className="addition-form__input-wrapper">
            <label>Тип оборудования</label>
            <div className="button-type-list addition-button-type-list addition-user-equipment-button-list">
            {EQUIPS.map((equip, index) => {
              return (
                <button key={index} type='button' className={`addition-button-add-equipment modal-change-equipment technic-type-${equipmentList.includes(equip) ? 'white' : 'black'}-${equip.toLowerCase()} ${equipmentList.includes(equip) ? 'selected' : ''}`}
                onClick={(e) => {handleSwitchEquipment(e.target.value); }}
                value={equip}
                />
              )
            })}
            </div>
            {(equipListError.state) &&
            <p className="validation-error-message">{equipListError.text}</p>
            }
          </div>

          <div className="chosen-dirplace chosen-dirplace-wrapper">

            {/* { chosenDirPlace.name ? (
              <>
                <p>{chosenIdType === 'directionId' ? 'Выбранное направление' : 'Выбранный участок'}: </p>
                <p className="chosen-dirplace-text">{chosenDirPlace.name}</p>
              </> )
              : dirplacesError.state ? (
                <p className="validation-error-message">{dirplacesError.text}</p>
              )
              : ''
            } */}

          </div>
          <div className="addition-submit-__wrapper">
          {submitError.state &&
                <p className="validation-error-message">{submitError.text}</p>
              }
            <button className='button-submit-direction' type='submit'>Добавить пользователя</button>
          </div>
        </form>
        </>
       {/* ) : (
        <div className="form-submitted-notification__container">
          <p className="form-submitted-note">Пользователь добавлен! Можете скопировать данные для входа:</p>
          <label className='form-submitted-input-label'>
            <span>логин:</span>
            <input ref={copyTextUsernameRef} type="text"readOnly={true} className='form-submitted-input' value={username}/>
            <button onClick={() => handleCopyText(copyTextUsernameRef, setIsUsernameCopied)} className="copy-text__button form-submitted-copy-text__button" />
            <div className={`copy-text-note ${isUsernameCopied ? 'copied' : ''}`}>Скопировано!</div>
          </label>
          <label className='form-submitted-input-label'>
            <span>пароль:</span>
            <input ref={copyTextPasswordRef} type="text" readOnly={true} className='form-submitted-input' value={password} />
            <button onClick={() => handleCopyText(copyTextPasswordRef, setIsPasswordCopied)} className="copy-text__button form-submitted-copy-text__button" />
            <div className={`copy-text-note ${isPasswordCopied ? 'copied' : ''}`}>Скопировано!</div>
          </label>
        </div>
       )
      } */}
      </section>
      {isModalNoteOpened &&
        <ModalNote
        note='Пользователь добавлен! Можете скопировать данные для входа:'
        login={username}
        password={password}
        onClose={handleCloseNoteModal}
         />
      }
    </>
  )
};

export default UserForm;
