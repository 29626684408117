import { useEffect, useRef, useState } from "react";
import VinPopUp from "./Desktop/VinPopUp";
// import WarningPopUp from "./Desktop/WarningPopUp"
import img from '../../Images/icons8-img-100.png';
import apiConfig from "../../apiConfig.json";
import MapPopup from "./MapPopup";
import GraphPopup from "./GraphPopup";
import LoadExcelFile from "./LoadExcelFile";


const Main = (props) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isTablet, setIsTablet] = useState(800 < window.innerWidth < 1000);
  const [showVinPopUp, setShowVinPopUp] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [showGraphPopup, setShowGraphPopup] = useState(false);
  const vinButtonRef = useRef(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  // const [shownWarningPopUp, setShownWarningPopUp] = useState(false);
  // const warnButtonRef = useRef(null);
  const {shifts, pagination, toggleShiftInfoOverlay, toggleGalleryPopUp, toggleVinInfoOverlay, accessToken, activeColumn} = props;
  const [vin, setVin] = useState('');
  const [model, setModel] = useState('');
  const [warrantyNumber, setWarrantyNumber] = useState('');
  const [technicType, setTechnicType] = useState('');
  const [imagesArray, setImagesArray] = useState([]);
  const [mainRoute, setMainRoute] = useState({});
  const [route, setRoute] = useState({});
  const [voltage, setVoltage] = useState({});
  const [expandedTrips, setExpandedTrips] = useState({});
  const [expandedTripsIndex, setExpandedTripsIndex] = useState(null);
  const shiftsDataExcelArray = [];


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const toggleVinPopUp = (event, vin, model, warrantyNumber, technicType, imagesArray) => {
    setShowVinPopUp(true);
    setVin(vin);
    setModel(model);
    setWarrantyNumber(warrantyNumber);
    setTechnicType(technicType);
    setImagesArray(imagesArray)
    const rect = event.currentTarget.getBoundingClientRect();
    const newTop = rect.top + window.scrollY;
    const newLeft = rect.left + window.scrollX + vinButtonRef.current.offsetWidth + 10;
    setTop(newTop);
    setLeft(newLeft);
  }

  // const toggleWarningPopUp = (event) => {
  //   setShownWarningPopUp(true);
  //   const rect = event.currentTarget.getBoundingClientRect();
  //   const newTop = rect.top + window.scrollY;
  //   const newLeft = rect.left + window.scrollX + warnButtonRef.current.offsetWidth + 10;
  //   setTop(newTop);
  //   setLeft(newLeft);
  // }

  const toggleTrips = (index) => {
    setExpandedTrips(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
    setExpandedTripsIndex(index === expandedTripsIndex ? null : index);
  };

  const formatTripsLabel = (tripsCount) => {
    const lastDigit = tripsCount % 10;
    const lastTwoDigits = tripsCount % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${tripsCount} Рейсов`;
    } else if (lastDigit === 1) {
      return `${tripsCount} Рейс`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `${tripsCount} Рейса`;
    } else {
      return `${tripsCount} Рейсов`;
    }
  }

  const toggleMapPopup = (mainRoute, route) => {
    setShowMapPopup(!showMapPopup);
    setMainRoute(mainRoute);
    setRoute(route);
  }

  const toggleGraphPopup = (voltage) => {
    setShowGraphPopup(!showGraphPopup);
    setVoltage(voltage)
  }

  const transformShift = (shift) => {
    return {
      end: new Date(shift.end).toISOString().slice(0, -1),
      place_id: 4,
      login: shift.user.fullName,
      start: new Date(shift.start).toISOString().slice(0, -1),
      route: shift.route,
      trips: shift.trips.map(trip => ({
        equipmentId:337,
        tripEnd: new Date(trip.end).toISOString().slice(0, -1),
        tripId: trip.id,
        tripRoute: trip.route,
        tripStart: new Date(trip.start).toISOString().slice(0, -1),
      }))
    };
  };

  // console.log(transformShift(shifts))



  if (!shifts || shifts.length === 0) return (
    <>
      <div className="loading-data-shift-table">
        <p>
          Данные не были найдены
        </p>
      </div>
    </>
  )



  return(
    <>
    <MapPopup show={showMapPopup} onClose={toggleMapPopup} mainRoute={mainRoute} route={route}/>
    <GraphPopup show={showGraphPopup} onClose={toggleGraphPopup} voltage={voltage}/>
    <div className="page-wrapper">
        {/* <WarningPopUp show={shownWarningPopUp} top={top} left={left}/> */}
        <VinPopUp
          show={showVinPopUp}
          onClose={() => setShowVinPopUp(false)}
          top={top}
          left={left}
          vin={vin}
          model={model}
          warrantyNumber={warrantyNumber}
          technicType={technicType}
          imagesArray={imagesArray}
          toggleGalleryPopUp={toggleGalleryPopUp}
          />
        <main className="main-container">
          <section className={isMobile ? 'main-table-mobile' : 'main-table'}>
            <div className='table'>
              {shifts.map((shift, index) => {
                  const key = index;

                  shiftsDataExcelArray.push(transformShift(shift))
                  // console.log(shiftsDataExcelArray)

                  const user = shift.user.fullName;
                  const equipment = shift.trips[0]?.equipment;

                  const replaceEquipmentVin = () => {
                    if (equipment === null || equipment === undefined) {
                      return 1324234;
                    } else {
                      return equipment.vin;
                    }
                  }
                  const equipmentVin = replaceEquipmentVin();

                  const replaceEquipmentWarrantyNum = () => {
                    if (equipment === null || equipment === undefined) {
                      return '000';
                    } else {
                      return equipment.warrantyNumber;
                    }
                  }
                  const equipmentWarrantyNum = replaceEquipmentWarrantyNum();

                  const replaceEquipmentModel = () => {
                    if (equipment === null || equipment === undefined) {
                      return 'SLKCMCSWKV';
                    } else {
                      return equipment.model;
                    }
                  }
                  const equipmentModel = replaceEquipmentModel();

                  const replaceEquipmentType = () => {
                    if (equipment === null || equipment === undefined) {
                      return 'EXCAVATOR';
                    } else {
                      return equipment.type.type;
                    }
                  }
                  const equipmentType = replaceEquipmentType();

                  const getImageForEquipmentType = (equipmentType) => {
                    switch (equipmentType) {
                      case 'EXCAVATOR':
                        return require('../../Images/Equipment/excavator_32.png');
                      case 'BULLDOZER':
                        return require('../../Images/Equipment/bulldozer_32.png');
                      case 'CRANE':
                        return require('../../Images/Equipment/crane_32.png');
                      case 'GRADER':
                        return require('../../Images/Equipment/grader_32.png');
                      case 'RINC':
                        return require('../../Images/Equipment/rinc_32.png');
                      case 'RINK':
                        return require('../../Images/Equipment/rink_32.png');
                      case 'TRUCK':
                        return require('../../Images/Equipment/truck_32.png');
                      case 'POOKER':
                        return require('../../Images/Equipment/camera_10.png');
                      case 'AUTO':
                        return require('../../Images/Equipment/auto_32.png');
                      case 'REFUELER':
                        return require('../../Images/Equipment/refueler_512.png');
                      default:
                        return require('../../Images/Equipment/excavator_32.png');
                    }
                  };

                  const imagesArray = Object.keys(shift.photosImagePath).map(key => ({key, value: shift.photosImagePath[key]}));

                  // console.log(imagesArray)

                  const place = shift.trips[0]?.place;

                  const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}.${month}.${year}`;
                  };

                  const formatTime = (dateString) => {
                    const date = new Date(dateString);
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    return `${hours}:${minutes}`;
                  };

                  const trips = shift.trips;
                  const tripsCount = trips.length;

                  const tripsImages = [];
                  shift.trips.forEach(trip => {
                    if(trip.photosImagePath) {
                      const tripsPhotos = Object.keys(trip.photosImagePath).map(key => ({key, value: trip.photosImagePath[key]}));
                      tripsImages.push(...tripsPhotos);
                    }
                  });
                  let tripsImage = tripsImages.length > 0 && equipmentType !== 'AUTO' ? `${apiConfig.BASE_URL}` + tripsImages[0].value : img;
                  // console.log('tripsImages: ', tripsImage)
                  const voltage = Object.keys(shift.voltage);
                  const sortedVoltageKeys = Object.keys(shift.voltage).sort();
                  const sortedVoltage = {};
                  sortedVoltageKeys.forEach(key => {
                    sortedVoltage[key] = shift.voltage[key];
                  });

                  const route = Object.keys(shift.route);
                  const shiftRouteKeys = Object.keys(shift.route).sort();
                  const sortedMainRoute = {};
                  shiftRouteKeys.forEach(key => {
                    sortedMainRoute[key] = shift.route[key];
                  });


                  const allTripsEmpty = shift.trips.every(trip => {
                    const sortedRouteKey = Object.keys(trip.route);
                    return sortedRouteKey.length === 0;
                  })

                  const sortedRoutes = [];

                  if (allTripsEmpty) {
                    const shiftRouteKeys = Object.keys(shift.route);
                    const sortedRoute = {};
                    shiftRouteKeys.forEach(key => {
                      sortedRoute[key] = shift.route[key];
                    });

                  } else {
                    shift.trips.forEach(trip => {
                      const sortedRouteKey = Object.keys(trip.route).sort();
                      const sortedRoute = {};
                      sortedRouteKey.forEach(key => {
                        sortedRoute[key] = trip.route[key];
                      });
                      sortedRoutes.push(sortedRoute);
                    });
                  }

                  return (
                    <div className='table-body' key={key}>
                          <div className={isMobile ? 'table-row--primary table-row--primary-mobile' : 'table-row--primary'}>
                            <ul className="table-row-list table-row-list--primary">
                              <li className={isMobile ? 'inactive' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                {/* <button className="button-warning-desktop"
                                  ref={warnButtonRef}
                                  onClick={toggleWarningPopUp}
                                  onMouseEnter={(event) => toggleWarningPopUp(event)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <img src={warning} alt='warning'/>
                                </button> */}
                              </li>
                              <li className={isMobile ? 'table-row-item table-row-item-mobile' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                {/* <button className={isMobile ? "button-warning-mobile": 'inactive'} onClick={toggleWarningInfoOverlay}></button> */}
                                {user}
                              </li>
                              <li className={isMobile ? activeColumn === 0  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                {
                                  isMobile ? (
                                    <button className="vin-pop-up" ref={vinButtonRef}
                                      style={{backgroundImage: `url(${getImageForEquipmentType(equipmentType)})`}}
                                      onClick={() => toggleVinInfoOverlay(equipmentVin, equipmentModel, equipmentWarrantyNum, equipmentType, imagesArray)}>
                                      <p>{equipmentWarrantyNum}</p>
                                    </button>
                                  ) : (
                                      <button className="vin-pop-up" ref={vinButtonRef}
                                      style={{backgroundImage: `url(${getImageForEquipmentType(equipmentType)})`}}

                                      onClick={(event) => toggleVinPopUp(event, equipmentVin, equipmentModel, equipmentWarrantyNum, equipmentType, imagesArray)}>
                                        <p>{equipmentWarrantyNum}</p>
                                      </button>
                                  )
                                }
                              </li>
                              <li className={isMobile ? activeColumn === 1  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatDate(shift.start)}
                              </li>
                              <li className={isMobile ? activeColumn === 2  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatTime(shift.start)}
                              </li>
                              <li className={isMobile ? activeColumn === 3  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatTime(shift.end)}
                              </li>
                              <li className={isMobile ? activeColumn === 4  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {shift.shiftPlace.directionName}
                              </li>
                              <li className={isMobile ? activeColumn === 5  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {shift.shiftPlace.name}
                              </li>
                              <li className={isMobile ? 'inactive' : 'table-row-item'}>
                                <button className={ expandedTrips[index] ? 'button-races button-races-opened' : 'button-races button-races-closed'} onClick={() => toggleTrips(index)}>
                                    {formatTripsLabel(tripsCount)}
                                </button>
                              </li>
                              <li className={isMobile ? 'inactive' : 'table-row-item'}></li>
                              {
                                expandedTrips[index] ? (
                                  <li className={isMobile ? activeColumn === 6  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                        <button className="button-gallery" onClick={() => toggleGalleryPopUp(tripsImages, tripsImage)}>
                                          {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={45} alt='фото смены'/>
                                          ) : (
                                            <img src={tripsImage} width={55} height={45} alt='фото смены'/>
                                          )}
                                        </button>
                                  </li>
                                ) : (
                                  <li className={isMobile ? activeColumn === 6  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item not-expanded'} rowSpan={tripsCount + 2}>
                                        <button className="button-gallery" onClick={() => toggleGalleryPopUp(tripsImages, tripsImage)}>
                                        {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={45} alt='фото смены'/>
                                          ) : (
                                            <img src={tripsImage} width={55} height={45} alt='фото смены'/>
                                          )}
                                        </button>
                                  </li>
                                )
                              }
                              {
                                expandedTrips[index] ? (
                                  <li className={isMobile ? activeColumn === 7  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                    {
                                      route.length === 0 || allTripsEmpty === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoutes)}></button>
                                      )
                                    }
                                  </li>
                                ) : (
                                  <li className={isMobile ? activeColumn === 7  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item not-expanded'} rowSpan={tripsCount + 2}>
                                    {
                                      route.length === 0 || allTripsEmpty === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoutes)}></button>
                                      )
                                    }
                                  </li>
                                )
                              }
                              <li className={isMobile ? activeColumn === 8  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                {
                                  voltage.length === 0 ? (
                                    <p>-</p>
                                  ) : (
                                    <button className="button-show-graph" onClick={() => toggleGraphPopup(sortedVoltage)}></button>
                                  )
                                }
                              </li>
                              <li className={isMobile ? 'table-row-item table-row-item-mobile' : 'inactive'}>
                                <div className="button-info-wrapper">
                                  <button className="button-more-info"
                                    onClick={() => toggleShiftInfoOverlay(user, equipmentWarrantyNum, formatDate(shift.start), formatTime(shift.start), formatTime(shift.end), place, trips, tripsImages, equipmentType, sortedMainRoute, sortedRoutes, sortedVoltage)}
                                  ></button>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="expanded-rows-wrapper">
                          {
                            expandedTrips[index] && trips.map((trip, index) => {
                              const key = index;
                              const tripPlace = trip.place;
                              const tripWorktype = trip.workType;
                              const tripImagesArray = Object.keys(trip.photosImagePath).map(key => ({key, value: trip.photosImagePath[key]}));
                              const tripImage = `${apiConfig.BASE_URL}` + tripImagesArray[0].value;
                              // console.log(tripImage)
                              const route = Object.keys(trip.route);
                              const sortedRouteKey = Object.keys(trip.route).sort();
                              const sortedRoute = {};
                              sortedRouteKey.forEach(key => {
                                sortedRoute[key] = trip.route[key];
                              });

                              return(
                              <div className={isMobile ? 'inactive' : 'table-row-expanded'} key={key}>
                                <ul className="table-row-list--primary">
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item trips-opened-cell'>{formatDate(trip.start)}</li>
                                  <li className='table-row-item trips-opened-cell'>{formatTime(trip.start)}</li>
                                  <li className='table-row-item trips-opened-cell' >{formatTime(trip.end)}</li>
                                  <li className='table-row-item'>{trip.tripPlace.directionName}</li>
                                  <li className='table-row-item'>{trip.tripPlace.name}</li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'>{tripWorktype}</li>
                                  <li className='table-row-item'>
                                    <button className="button-gallery" onClick={() => toggleGalleryPopUp(tripImagesArray, tripImage)}>
                                          {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={45} alt='фото смены'/>
                                          ) : (
                                            <img src={tripImage} width={55} height={45} alt='фото смены'/>
                                          )}
                                    </button>
                                  </li>
                                  <li className="table-row-item">
                                    {
                                      route.length === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoute)}></button>
                                      )
                                    }
                                  </li>
                                  <li className='table-row-item'></li>
                                </ul>
                              </div>
                              )
                            })
                          }
                          </div>
                    </div>)
                  }
                )
              }
            </div>
            <section className={isMobile ? 'table-footer__wrapper table-footer__wrapper-mobile' : "table-footer__wrapper"}>
              <LoadExcelFile accessToken={accessToken} shiftDataExcel={shiftsDataExcelArray}/>
              {pagination}
            </section>
          </section>
        </main>
      </div>
    </>
  );
};

export default Main;
